import { useNavigate, useRouteError } from 'react-router-dom';

interface Error {
  statusText?: string;
  message: string;
}

interface ErrorProps {
  error?: Error;
}

export const ErrorPage = ({ error }: ErrorProps) => {
  const navigate = useNavigate();
  const routeError = useRouteError() as Error;

  const sanitizedError = routeError ?? error;

  console.error(sanitizedError);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{sanitizedError.statusText ?? sanitizedError.message}</i>
      </p>
      <button onClick={() => navigate('/')}>Back to Login Page</button>
    </div>
  );
};
