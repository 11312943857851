import { IMessage } from '../types/message';

export async function createSession(accessToken: string) {
  const body = {
    context: 'You are an AI that is cynical and jaded. You will give every answer in a very hostile manner.',
  };

  const response = await fetch(`${window.location.origin}/api/chat/session`, {
    method: 'POST',
    headers: new Headers({ authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });

  const id = await response.text();

  sessionStorage.setItem('CHAT_SESSION', id);

  return id;
}

export async function getSession(sessionId: string, accessToken: string) {
  const response = await fetch(`${window.location.origin}/api/chat/session/${sessionId}`, {
    method: 'GET',
    headers: new Headers({ authorization: `Bearer ${accessToken}` }),
  });

  const responseJson = await response.text();
  const responseObj = await JSON.parse(responseJson);

  return responseObj as { messages: IMessage[] };
}

export async function deleteSession(sessionId: string, accessToken: string) {
  await fetch(`${window.location.origin}/api/chat/session/${sessionId}`, {
    method: 'DELETE',
    headers: new Headers({ authorization: `Bearer ${accessToken}` }),
  });

  sessionStorage.removeItem('CHAT_SESSION');
}

export async function getReply(message: IMessage, tenantId: string, accessToken: string) {
  const { source, content } = message;

  const response = await fetch(`${window.location.origin}/api/chat/completion`, {
    method: 'POST',
    headers: new Headers({ authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      serviceId: 'portal',
      sessionId: sessionStorage.getItem('CHAT_SESSION'),
      tenantId,
      message: {
        source,
        content,
        timestamp: new Date(),
      },
    }),
  });

  const replyJson = await response.text();
  const reply = await JSON.parse(replyJson);

  return reply as IMessage;
}
