import { useIdentityAuth, useSignoutRedirect } from '@uipath/auth-react';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { createSession, deleteSession, getReply, getSession } from '../util/api';
import { mapMessage } from '../util/mapper';

const styles: Record<string, React.CSSProperties> = {
  container: {
    border: '1px solid white',
    borderRadius: '20px',
    minHeight: '70vh',
    minWidth: '70vw',
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    flex: 0.85,
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 30px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottom: {
    flex: 0.15,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 30px 30px 30px',
  },
  chat: {
    backgroundColor: 'rgb(59, 59, 59)',
    borderRadius: '8px',
    textAlign: 'left',
    padding: '0px 12px',
    overflow: 'auto',
    flex: 1,
    width: 'calc(100% - 24px)',
  },
  form: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tenantId: {
    width: 'calc(100% - 4px)',
    flex: 0.15,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '30px',
  },
  input: {
    height: '48px',
    border: 'none',
    flex: 0.9,
    borderRadius: '8px',
    padding: '0 12px',
  },
};

const initialMessages = ['Welcome to the sample chat application for Jarvis!', 'Click "Start Chat" to start a new session.'];

export const Chat = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<string[]>([]);

  const [tenantId, setTenantId] = useState(localStorage.getItem('tenantId') ?? '');

  const { user, accessToken } = useIdentityAuth();
  const signoutRedirect = useSignoutRedirect();

  const bottomRef = useRef(null);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    createChatSession();
  }, []);

  const createChatSession = useCallback(async () => {
    let sessionId = sessionStorage.getItem('CHAT_SESSION');
    if (!sessionId) {
      sessionId = await createSession(accessToken);
    }

    const session = await getSession(sessionId, accessToken);
    const chatMessages = session.messages.map((m) => mapMessage(m));

    setMessages(initialMessages.concat(chatMessages));
  }, []);

  const recreateChatSession = useCallback(async () => {
    const id = sessionStorage.getItem('CHAT_SESSION');

    if (id) {
      await deleteSession(id, accessToken);
      setMessages([]);
    }

    await createChatSession();
  }, []);

  const sendMessage = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!tenantId) {
        setMessages(['Missing tenant ID! Please input a tenant ID in the input above.']);
        return;
      }

      const sender = user.profile.first_name as string;
      const content = input;

      const message = { source: sender, content };

      setMessages((u) => u.concat(mapMessage(message)));
      setInput('');

      const reply = await getReply(message, tenantId, accessToken);

      setMessages((m) => m.concat(mapMessage(reply)));
    },
    [input]
  );

  const saveTenantId = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.target as HTMLFormElement);

      if (tenantId) {
        localStorage.removeItem('tenantId');
        setTenantId('');
        return;
      }

      const tenantIdInput = formData.get('tenantId').toString();
      localStorage.setItem('tenantId', tenantIdInput);
      setTenantId(tenantIdInput);
    },
    [tenantId]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2>Hello!</h2>
      <div style={styles.container}>
        <div style={styles.top}>
          <div style={styles.tenantId}>
            <form style={styles.form} onSubmit={saveTenantId}>
              <input
                type="text"
                name="tenantId"
                style={styles.input}
                placeholder="Enter tenant ID here..."
                defaultValue={tenantId}
                disabled={!!tenantId}
              ></input>
              <button type="submit" style={{ flex: 0.08, minWidth: '100px' }}>
                {tenantId ? 'Edit' : 'Submit'}
              </button>
            </form>
          </div>
          <div style={styles.chat}>
            {messages.map((m, i) => {
              const [sender, message] = m.split('|:|');

              if (!message) {
                return <p key={i}>{sender}</p>;
              }

              const color = sender === 'assistant' ? 'magenta' : 'cyan';

              return (
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '28px' }} key={i}>
                  <b style={{ color }}>{sender.charAt(0).toUpperCase() + sender.slice(1)}:</b>&nbsp;&nbsp;<p>{message}</p>
                </div>
              );
            })}
            <div ref={bottomRef} />
          </div>
        </div>
        <div style={styles.bottom}>
          <form style={styles.form} onSubmit={sendMessage}>
            <input
              style={styles.input}
              placeholder="Enter message here..."
              value={input}
              onChange={(e) => setInput(e.target.value ?? '')}
            ></input>
            <button style={{ flex: 0.08 }}>Send</button>
          </form>
        </div>
      </div>
      <button onClick={() => recreateChatSession()}>Start Chat</button>
      <button onClick={() => signoutRedirect()}>Logout</button>
    </div>
  );
};
