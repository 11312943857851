import './App.css';

import { onCallback, UiPathAuthProvider } from '@uipath/auth-react';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { Configuration } from './auth/configuration';
import { withAuth } from './auth/withAuth';
import { ErrorPage } from './routes/Error';
import { Root } from './routes/Root';
import { Chat } from './routes/Chat';

const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/portal_/cloudrpa',
    element: <Navigate to="/" />,
  },
  {
    path: '/portal_/loginsuccess',
    element: null, // render loader here if desired
  },
  {
    path: '/portal_/autherror',
    element: <ErrorPage />,
  },
  {
    path: '/portal_/sessionexpired',
    element: <ErrorPage error={{ message: 'Session Expired!' }} />,
  },
];

const protectedRoutes: RouteObject[] = withAuth([
  {
    path: '/portal_/chat',
    element: <Chat />,
  },
]);

const router = createBrowserRouter([...publicRoutes, ...protectedRoutes]);

const Fallback = () => <div>Loading...</div>;

const App = () => (
  <UiPathAuthProvider
    configuration={Configuration}
    onSigninCallback={(user) => onCallback(user, router.navigate)}
    onError={(error) => router.navigate('/portal_/autherror', { state: error })}
  >
    <RouterProvider router={router} fallbackElement={<Fallback />} />
  </UiPathAuthProvider>
);

export default App;
