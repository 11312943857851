import { useNavigate } from 'react-router-dom';

export const Root = () => {
  const navigate = useNavigate();

  return (
    <div className="App">
      <div>
        <img src="https://staticresourcescp.blob.core.windows.net/auth0-spa/UiPath-full-logo.svg" className="logo" alt="UiPath logo" />
      </div>
      <h1>Jarvis Chat Example</h1>
      <div className="card">
        <button onClick={() => navigate('/portal_/chat')}>Login</button>
        <p className="read-the-docs">Click above to login</p>
      </div>
    </div>
  );
};
