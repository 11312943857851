import { AuthGuard } from '@uipath/auth-react';
import type { RouteObject } from 'react-router-dom';

export function withAuth(routes: RouteObject[]): RouteObject[] {
  return routes.map((route) => ({
    ...route,
    element: (
      <AuthGuard
        extraSigninRequestArgs={{
          organizationName: import.meta.env.VITE_ACR_VALUES,
          state: { returnTo: route.path },
        }}
      >
        {route.element}
      </AuthGuard>
    ),
  }));
}
