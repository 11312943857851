import type { AuthConfiguration } from '@uipath/auth-react';
import { getClientOrigin } from '../util/origin';

export const IdentityUrl = 'https://alpha.uipath.com/identity_';

export const Configuration: AuthConfiguration = {
    authority: IdentityUrl,
    client_id: '9a5129d2-5678-4e3e-97f7-46560f016114',
    scope: 'openid profile email offline_access',
    redirect_uri: `${getClientOrigin()}portal_/loginsuccess`,
    post_logout_redirect_uri: `${getClientOrigin()}portal_/cloudrpa`,
};
