export function isLocal() {
  return window.location.origin.includes('local');
}

export function getServerOrigin() {
  const uri = isLocal() ? 'http://127.0.0.1:8787' : import.meta.env.BASE_URL;

  const url = new URL(uri);

  return url.host;
}

/**
 *
 * @returns origin of client, suffixed with '/'
 */
export function getClientOrigin() {
  return isLocal() ? 'http://localhost:3000/' : import.meta.env.BASE_URL;
}
